<template>
  <div>
    <b-button variant="primary">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else icon="PlusIcon" class="mr-50" />
      <span class="align-middle" @click="isModalShow=true; test">Add Milestone</span>
    </b-button>
    <b-modal
      v-model="isModalShow"
      title="Create new milestone"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <validation-observer
        ref="createForm"
        v-slot="{ handleSubmit }"
      >
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <validation-provider
            name="Title"
            v-slot="validationContext"
            :rules="{ required: true }"
          >
            <b-form-group
              label="Title"
              label-for="h-milestone-id"
            >
              <b-form-input 
                v-model="milestone_id"
                type="number"
              />
              <b-form-invalid-feedback id="milestone-id-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
        
        <div class="d-flex justify-content-between my-2">
          <b-button variant="danger" @click="cancel">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="create">Create</b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      isModalShow: false,
      milestone_id: null,
    };
  },
  methods: {
    async create() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.isLoading = true;
        let new_data = await service.create({id: this.milestone_id});
        this.isLoading = false;
        if (new_data) {
          this.$router.push({
            name: "arena-milestone-detail",
            params: { milestone_id: new_data._id },
          });
        }
      }
    },
    cancel(){
      this.isModalShow = false;
      this.milestone_id = null;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    test(){
    }
  },
};
</script>
