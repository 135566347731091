<template>
  <div class="page-content">
    <page-breadcrumb title="Arena Milestone" />
    <section>
      <div class="text-center">
        <milestone-create-form />
      </div>
    </section>
    <section class="mt-3 d-flex">
      <b-row class="w-100">
        <b-col
          v-for="(item, index) in milestones"
          :key="index"
          class="col-lg-2 col-md-2 col-12"
        >
          <b-link
            :to="{
              name: 'arena-milestone-detail',
              params: { milestone_id: item._id },
            }"
            :id="`milestone_${index+1}`"
          >
            <b-card class="text-center">
              <div>
                Milestone {{ item.id }}
              </div>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </section>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import MilestoneCreateForm from './_components/MilestoneCreateForm.vue'
export default {
  components: {
    MilestoneCreateForm
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      milestones: null,
      currentStageToGo: null,
    };
  },
  watch: {
    current_page() {
      this.getAll();
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if(response){
        this.milestones = response.list;
        this.total_items = response.total
      }
    },
    goToStage(){
      document.getElementById('stage_'+this.currentStageToGo).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
};
</script>
<style scoped>
.badge-secondary {
  background-color: #f8f8f8;
}
</style>
